/***********************************************
 Video courses pages funtionality
 ***********************************************/
(function() {
    var smallScreen = function() {
        return isMobile || $window.width() < 960;
    },

	/* Get video functional */
    playlistVideo = function(_onUpdate) {
        var root = this,
            $page = $('.video-single'),
            $player = smallScreen() ? $('#popup-single-video .video-player') : $('.video-player'),
            $sidebar = $('.video-single_sidebar'),
            $recipe = false,
            $recipeMobile = $('#popup-single-video .video-recipe');

        root.videoStack = [];
        $('.playlist_item').each(function() {
            root.videoStack.push($(this).find('[data-get-video-id]').attr('data-get-video-id'));
        });

        root.url = $('[data-get-video-url]').data('get-video-url');
        root.currentId = false;

        // not present in markup-verison
        $player.on("click", ".mejs-overlay-play--play", function () {

            if (!$(".js-masterclass-player", $player).hasClass("active")) {
                $(".js-masterclass-player", $player).addClass("active");
                window.kinescopePlayer.play();
            }
        });
        // end not present

		/* Helpers */
        function insertVideo() {

            if (root.data.access == "true") {

                var playerHTML = '',
                    recipeHTML = '',
                    stackIndex = root.videoStack.indexOf(root.data.id),
                    nextId,
                    favHTML = '',
                    favClass = '';

                if (smallScreen()) {
                    // set next video link
                    nextId = (stackIndex + 1) < root.videoStack.length ? root.videoStack[stackIndex + 1] : root.videoStack[0];
                    $('.video-next').attr('data-get-video-id', nextId);

                } else {
                    // add class
                    $page.addClass('video-single--secondary');
                }

                // insert player
                $player.css( {'height': $player.height()} ).removeClass('video-player--promo video-player--activated');
                if (root.data.access == "true") {
                    playerHTML = '<div class="mejs-container" tabindex="0" style="width: 100%; height: 100%;">' +
                        '<div class="mejs-layers">' +
                        '<div class="mejs-poster mejs-layer" style="background-image: url(' + root.data.poster + '); width: 100%; height: 100%;"></div>' +
                        '<div class="mejs-overlay mejs-layer mejs-overlay-play mejs-overlay-play--play" style="width: 100%; height: 100%;">' +
                        '<div class="mejs-overlay-button" role="button" aria-label="Play" aria-pressed="false"></div> </div>' +
                        '<div class="master-class-video__player js-masterclass-player">' + '<div id="player-placeholder"></div>' +
                        '</div> </div> </div>';

                } else {
                    playerHTML = '<div class="video-player_lock"><img src="' + root.data.poster + '" title="Просмотр видеоуроков доступен только зарегистрированным пользователям, купившим подписку"></div>';
                }
                if (smallScreen()) {
                    $player.html(playerHTML);
                } else {
                    $player.find(".video-player-replace").html(playerHTML);
                }

                // play

                if (window.kinescopePlayer) {
                    window.kinescopePlayer.destroy();
                }
                window.playerFactory.create('player-placeholder', {
                    url: 'https://kinescope.io/' + root.data.video,
                    size: {width: '100%', height: '100%'},
                    behaviour: {
                        autoPlay: false,
                        playsInline: false,
                        localStorage: false,
                    }


                }).then(function (player) {
                    window.kinescopePlayer = player;
                });

                // end play

                if (window.authorizedSession) {
                    if (root.data.fav === 'true')
                        favClass = ' _active';

                    favHTML = '<div class="favorite-trigger favorite-trigger_inline' + favClass + '" data-masterclass-id="' + root.data.mk_id + '" data-recipe-id="' + root.data.id + '" data-type="1"></div>';
                }

                // insert recipe description
                if (root.data['with-description'] == 'true') {
                    recipeHTML = '<div class="video-recipe_item"><div class="video-recipe_item_title video-recipe_item_title--lg">'+ root.data.name +'</div><div class="video-recipe_item_content"><div class="video-description_meta">';
                    if (root.data.author)
                        recipeHTML += '<a href="'+ root.data.author.link +'" class="video-description_author">'+ root.data.author.name +'</a>';
                    if (root.data.time)
                        recipeHTML += '<div class="video-description_time">'+ root.data.time +'</div>';
                    recipeHTML += favHTML;
                    recipeHTML += '</div>';
                    if (root.data.description)
                        recipeHTML += '<p class="video-description_text">'+ root.data.description +'</p>';
                    if (root.data.tags.length) {
                        recipeHTML += '<div class="tags">';
                        for (var i = 0; i < root.data.tags.length; i++) {
                            if (i == 6) {
                                recipeHTML += '<a href="#" class="tag tags_more">еще...</a><div class="tags_hidden">';
                            }

                            recipeHTML += '<a href="'+ root.data.tags[i].link +'" class="tag">'+ root.data.tags[i].name +'</a>';

                            if (i >= 6 && i == root.data.tags.length - 1)
                                recipeHTML += '</div>';
                        }
                        recipeHTML += '</div>';
                    }
                    recipeHTML += '</div></div>';
                } else {
                    recipeHTML = '<div class="video-recipe_item video-recipe_item--pinned"><div class="video-recipe_item_title video-recipe_item_title--lg">'+ root.data.name +'</div></div>';

                    if (window.authorizedSession) recipeHTML += '<div class="video-recipe_item--active"><div class="video-recipe_item_content">' + favHTML + '</div></div>';
                }

                // insert recipe ingridients
                if (root.data.ingridients.length) {
                    recipeHTML += '<div class="video-recipe_item video-recipe_item--active"><div class="video-recipe_item_title">Ингредиенты</div><div class="video-recipe_item_content video-recipe_item_content--nopad"><table class="video-recipe_ingredients"><tbody>';
                    for (var i = 0; i < root.data.ingridients.length; i++) {
                        if (root.data.ingridients[i].value === "group") {
                            recipeHTML += '<tr><td class="ingredients_group" colspan="2">' + root.data.ingridients[i].name + '</td>';
                        } else {
                            recipeHTML += '<tr><td class="ingredients_item">' + root.data.ingridients[i].name + '</td><td class="ingredients_value">' + root.data.ingridients[i].value + '</td></tr>';
                        }
                    }
                    recipeHTML += '</tbody></table></div></div>';
                }

                // insert recipe text
                if (root.data.recipe) {
                    recipeHTML += '<div class="video-recipe_item video-recipe_item--active"><div class="video-recipe_item_title">Рецепт приготовления</div><div class="video-recipe_item_content">'+ root.data.recipe +'<div class="video-question hidden-md-up"><div class="video-question_title">Что-то не получается? <span>Задайте вопрос шефу!</span></div>' + $('.video-question_form:last').get(0).outerHTML + '</div></div></div>';
                }

                // insert recipe partners
                if (root.data.partners.length) {
                    recipeHTML += '<div class="video-recipe_item video-recipe_item--active video-recipe_item--pinned"><div class="video-recipe_item_title">Инструментарий</div><div class="video-recipe_item_content"><div class="video-partners video-partners--slider"><div class="video-partners_list">';
                    for (var i = 0; i < root.data.partners.length; i++) {
                        recipeHTML += root.data.partners[i];
                    }
                    recipeHTML += '</div></div></div></div>';
                }

                if (smallScreen()) {

                    $recipeMobile.html(recipeHTML);

                    if ($page.hasClass("video-single--standalone")) {
                        $(".mejs-overlay-play--play", $player).click();
                    } else {
                        $('html, body').animate({ 'scrollTop': $page.offset().top });
                    }
                    needPopup.show('#popup-single-video');
                    setTimeout(function () {
                        $player.height($player.width() / 1.77);
                    }, 500);
                } else {
                    if ($page.hasClass("video-single--parent")) {
                        if (!$recipe) {
                            $recipe = $('<div class="video-recipe"></div>');
                            $sidebar.prepend($recipe);
                        }
                        $recipe.html(recipeHTML);
                    }

                    if ($page.hasClass("video-single--standalone")) {
                        $(".mejs-overlay-play--play", $player).click();
                    } else {
                        $('html, body').animate({ 'scrollTop': $page.offset().top });
                    }

                    root.update();
                }

            } else {

                if (smallScreen()) {
                    $('#popup-single-video_locker .container').html($('.video-player_locker').html());
                    needPopup.show('#popup-single-video_locker');
                } else {
                    $('html, body').animate({ 'scrollTop': $page.offset().top });
                    $player.addClass('video-player--locked');
                }

            }
        };

		/* Methods */
        root.init = function() {

            $(document).on('click', '[data-get-video-id]', function(event) {
                event.preventDefault();
                root.getVideo($(this).data('get-video-id'));
            });

            $window.on('load', function() {
                var hash = window.location.hash,
                    id;
                if (hash.search('getvideo-') > -1) {
                    id = hash.replace(/#getvideo-(.*)/g, '$1');
                    root.getVideo(id);
                }
            });

        };

        root.update = function() {
            if (typeof _onUpdate == 'function')
                _onUpdate.call(root);
        };

        root.getVideo = function(_id) {
            root.currentId = _id;

            $.ajax({
                type: 'GET',
                url: root.url + _id,
                dataType: 'json',
                success: function(data) {
                    root.data = typeof data == "string" ? JSON.parse(data) : data;
                    insertVideo();
                },
                error: function(data) {
                    console.log('Server error when trying to get video');
                }
            });
        };

        root.init();

    };

    // question form initialization
    function initQuestionForm($form) {

        var videoQuestionTimeout;
        $form.validate({
            ignore: '.ignore',
            errorPlacement: function(error, element) {
                element.addClass('error');
                element.after(error);
            },
            success: function(label, element) {
                $(element).removeClass('error');
                $(element).next('.error').remove();
            },
            submitHandler: function(form, event) {
                event.preventDefault();
                $('.video-question_success').remove();
                clearTimeout(videoQuestionTimeout);

                var formData = $(form).serializeArray();
                $.ajax({
                    type: form.getAttribute('data-ajax-method'),
                    url: form.getAttribute('action'),
                    data: formData,
                    dataType: form.getAttribute('data-ajax-datatype'),
                    success: function(data) {
                        var result = typeof data == "string" ? JSON.parse(data) : data;
                        if (result.status == 'fail') {
                            if ($('.video-question_server-errors')[0])
                                $('.video-question_server-errors').html('<div class="video-question_server-errors">' + result.error + '</div>');
                            else
                                $('.video-question_button').before('<div class="video-question_server-errors">' + result.error + '</div>');
                        } else {
                            $('.video-question_server-errors').hide();
                            $('.video-question_button').before('<div class="video-question_success">Спасибо, ваш вопрос отправлен!</div>');
                            videoQuestionTimeout = setTimeout(function() {
                                $('.video-question_success').fadeOut(function() {
                                    $(this).remove();
                                })
                            }, 3000);
                        }
                    },
                    error: function(data) {
                        if ($('.video-question_server-errors')[0])
                            $('.video-question_server-errors').html('<div class="video-question_server-errors">Произошла ошибка отправки. <br>Пожалуйста, попробуйте позже.</div>');
                        else
                            $('.video-question_button').before('<div class="video-question_server-errors">Произошла ошибка отправки. <br>Пожалуйста, попробуйте позже.</div>');
                    }
                });
            }
        });
    }

	/* Initializations
	 ***********************************************/
    // popup video config
    needPopup.config.video = {
        onShow: function(popup) {

            initQuestionForm($('#popup-single-video .video-question_form'));

            $('#popup-single-video .video-player_video.mejs-player').one('play', function() {
                var $this = $(this),
                    $parent = $this.closest('.video-player');
                $parent.addClass('video-player--activated');
            }).mediaelementplayer();

            setTimeout(function() {
                $('#popup-single-video .video-partners--slider .video-partners_list').bxSlider({
                    mode: 'horizontal',
                    slideSelector: '.video-partners_item',
                    pager: false,
                    nextText: '',
                    prevText: ''
                });
            }, 500);
        },
        onHide: function(popup) {
            //$('#popup-single-video video')[0].pause();
        }
    };

    needPopup.config.message = {
        'removerPlace': 'outside'
    };

    // init popups
    needPopup.init();

    // video player manipulate styles
    $('.video-player_video.mejs-player').one('play', function() {
        var $this = $(this),
            $parent = $this.closest('.video-player');
        $parent.removeClass('video-player--promo').addClass('video-player--activated');
    });

    // recipe slidedowns
    $(document).on('click', '.video-recipe_item_title', function(event) {
        event.preventDefault();

        if ($(this).closest('.video-recipe_item--pinned').length) return;

        var $this = $(this),
            $item = $this.closest('.video-recipe_item'),
            $content = $item.find('.video-recipe_item_content');

        if ($item.hasClass('video-recipe_item--active')) {
            $content.slideUp(300, function() {
                $item.removeClass('video-recipe_item--active');
            });
        } else {
            $content.slideDown(300, function() {
                $item.addClass('video-recipe_item--active');
            });
        }
    });

    // video question request
    $(document).on('click', '.video-question_title', function(event) {
        event.preventDefault();
        var $this = $(this),
            $item = $this.closest('.video-question'),
            $content = $item.find('.video-question_form');

        if ($item.hasClass('video-question--active')) {
            $content.slideUp(300, function() {
                $item.removeClass('video-question--active');
            });
        } else {
            $content.slideDown(300, function() {
                $item.addClass('video-question--active');
            });
        }
    });

    // player show locker
    $(document).on('click', '.video-player_lock', function(event) {
        event.preventDefault();

        if (smallScreen()) {
            $('#popup-single-video_locker .container').html($('.video-player_locker').html());
            needPopup.show('#popup-single-video_locker');
        } else {
            $('.video-player').addClass('video-player--locked');
        }
    });

    $('.video-question_form').each(function () {
        initQuestionForm($(this));
    });

    // initialize playlist video getter
    var playlistVideoGetter = new playlistVideo(function() {
        $('.video-single .video-player_video.mejs-player').one('play', function() {
            var $this = $(this),
                $parent = $this.closest('.video-player');
            $parent.addClass('video-player--activated');
        }).mediaelementplayer();

        $('.playlist_list').mCustomScrollbar('scrollTo', '[data-get-video-id="'+ this.currentId +'"]');
        $('.video-single .video-partners--slider .video-partners_list').bxSlider({
            mode: 'horizontal',
            slideSelector: '.video-partners_item',
            pager: false,
            nextText: '',
            prevText: ''
        });

    });

    // init partners slider
    $('.video-partners--slider .video-partners_list').bxSlider({
        mode: 'horizontal',
        slideSelector: '.video-partners_item',
        pager: false,
        nextText: '',
        prevText: ''
    });

    $window.on('load', function () {
        // scrollbar init
        if ($(".playlist_list").length)
            $(".playlist_list").mCustomScrollbar();
    });

    window.onKinescopeIframeAPIReady = function (playerFactory) {
        console.warn("onkinescopeready", playerFactory);
        window.playerFactory = playerFactory;
    }

    // Просмотр превью мастеркласса
    $(document.body).on('click', '.js-masterclass-preview', function () {
        var $video = $('.js-masterclass-player');

        $video.addClass('active').html('<div id="player-placeholder"></div>');


        if (window.kinescopePlayer) {
            window.kinescopePlayer.destroy();
        }
        window.playerFactory.create('player-placeholder', {
            url: 'https://kinescope.io/' + $('.js-masterclass-preview-code').html(),
            size: {width: '100%', height: '100%'},
            behaviour: {
                autoPlay: true,
                playsInline: false,
                localStorage: false,
            }
        }).then(function (player) {
            window.kinescopePlayer = player;
            player.on(player.Events.Ended, function (event) {
                $video.removeClass('active').html('');
                player.destroy();
            });
        });
    });

    var tag = document.createElement('script');
    tag.src = 'https://player.kinescope.io/latest/iframe.player.js';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

})();
