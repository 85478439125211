function sendMetrika(ya_name, ga_event, ga_type, ga_name, ga_action, ga_subname, callback, timeout) {
	
	var ya_name = ya_name || false,
		ga_event = ga_event || false,
        timeout = timeout || 2000,
        timeout_id = null,
        hit_callback = true;
    
    if (ya_name && typeof yaCounter43478419 !== 'undefined') {
        yaCounter43478419.reachGoal(ya_name);
    }
	
	if (ga_event) {
        timeout_id = setTimeout(function () {
            hit_callback = false;
            if (typeof callback == 'function') {
                callback.call();
            }
        }, timeout);
	    
		ga(ga_event, ga_type, ga_name, ga_action, ga_subname, {
            hitCallback: function() {
                clearTimeout(timeout_id);
                if (hit_callback && typeof callback == 'function') {
                    callback.call();
                }
            }
        });
    }

}

function sendConversionEvent() {
    console.warn('send conversion event');
    sendMetrika('conversion');
}

$(document).ready(function() {
	
	//Sharing
	$("body").on('click', '.social-likes__widget_facebook', function() {
		sendMetrika('Share_facebook', 'send', 'social', 'Facebook', 'Send', window.location.href);
	});
	$("body").on('click', '.social-likes__widget_vkontakte', function() {
		sendMetrika('Share_vkontakte', 'send', 'social', 'Vkontakte', 'Send', window.location.href);
	});
	$("body").on('click', '.social-likes__widget_twitter', function() {
		sendMetrika('Share_twitter', 'send', 'social', 'Twitter', 'Send', window.location.href);
	});
	$("body").on('click', '.social-likes__widget_plusone', function() {
		sendMetrika('Share_google', 'send', 'social', 'Google_plus', 'Send', window.location.href);
	});
	
	
	// Certificate
    $("body").on("click", ".subscription-form__section:not(.subscription-form__section_not-empty) [data-bind='click: chooseTariff']", function () {
        sendMetrika('AddTariffToCertificate', 'send', 'event', 'Voucher', 'Click', 'AddTariffToCertificate');
    });
    $("body").on("click", ".subscription-form__section [data-bind='click: chooseMasterclasses']", function () {
        sendMetrika('AddMasterclassToCertificate', 'send', 'event', 'Voucher', 'Click', 'AddMasterclassToCertificate');
    });
    $("body").on("click", ".subscription-form__section_not-empty [data-bind='click: chooseTariff']", function () {
        sendMetrika('Edit_Certificate', 'send', 'event', 'Voucher', 'Click', 'EditCertificate');
    });
    
    
    //Tarifs
    $("body").on("click", ".js-buy-tariff[data-promo-enabled]", function () {
        sendMetrika('Promo_Video', 'send', 'event', 'Subscription', 'Click', 'PromoVideo');
    });
    
});