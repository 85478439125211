function jgntSameHeight(strRule, nMaxInit) {

    elParents = $(strRule + '_parent');

    elParents.each(function (index) {

        if (typeof $(this).attr('id') === 'undefined')
            $(this).attr('id', 'jgnt_sameheight_parent_' + index);

        strParentRule = '#' + $(this).attr('id') + ' ' + strRule;

        if (nMaxInit == undefined)
            nMax = Math.max.apply(null,
                $(strParentRule).map(function () {
                    return $(this).height()
                }).get());
        else
            nMax = nMaxInit;

        $(strParentRule).height(nMax);

    });

    return elParents.length;
}

function jgntGetURL() {
    url = window.location.href;
    pos = url.indexOf('?');
    if (pos > 0)
        return url.substring(0, pos);
    else
        return '';
}

function jgntSetURL(strURL) {
    if (window.history)
        window.history.replaceState(null, null, strURL);
}

function jgntAddURL(strURL) {
    if (window.history)
        window.history.pushState(null, null, strURL);
}

function jgntUrlParam(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParamName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParamName = sURLVariables[i].split('=');

        if (sParamName[0] === sParam) {
            return sParamName[1] === undefined ? true : sParamName[1];
        }
    }
}

function jgntGetHref() {
    url = window.location.href;
    pos = url.indexOf('#');
    if (pos > 0)
        return url.substring(pos + 1);
    else
        return '';
}

function jgntSetHref(strNewURL) {
    url = window.location.href;
    if (strNewURL !== undefined && strNewURL.length) {
        newurl = url.substring(0, url.indexOf('#')) + '#' + strNewURL;
//		window.location = newurl;
        jgntSetURL(newurl);
    } else {
//		window.location.hash = "";
        newurl = url.substring(0, url.indexOf('#'));
        jgntSetURL(newurl);
    }
    return newurl;
}

/*
 function jgntHrefGetParam(sParam) {

 var sPageURL = decodeURIComponent( window.location.hash.substring(1) ),
 sURLVariables = sPageURL.split('&'),
 sParamName,
 i;

 for( i = 0; i < sURLVariables.length; i++ ){
 sParamName = sURLVariables[i].split('=');

 if( sParamName[0] === sParam ){
 return sParamName[1] === undefined ? true : sParamName[1];
 }
 }
 }
 */

function jgntHrefParse(strHref) {
    var strParams = {};

    if (strHref == undefined)
        strHref = decodeURIComponent(window.location.hash.substring(1));

    var sURLVariables = strHref.split('&'),
        sParamName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        if (sURLVariables != "") {
            sParamName = sURLVariables[i].split('=');
            strParams[sParamName[0]] = sParamName[1];
        }
    }

    return strParams;
}

function jgntHrefGetParam(strParams, strParam) {
    if (strParams.hasOwnProperty(strParam)) {
        if (strParams[strParam] !== undefined)
            return strParams[strParam];
        else
            return "";
    } else
        return false;
}

function jgntHrefCheckParam(strParams, strParam) {
    if (strParams.hasOwnProperty(strParam))
        return true;
    else
        return false;
}


function jgntHrefMake(strParams, strNewParam, strNewValue, strDeleteParam) {
    var strHref = "";
    var nFound = false;

    for (param in strParams) {

        if (strHref != "")
            strHref += "&";

        if (param != strDeleteParam) {
            strHref += param;

            if (param == strNewParam)
                nFound = true;

            if (strParams[param] !== undefined) {
                if (param == strNewParam) {
                    if (strNewValue !== undefined && strNewValue != "")
                        strHref += "=" + strNewValue;
                } else
                    strHref += "=" + strParams[param];
            }
        }
    }

    if (strNewParam != undefined && !nFound) {
        if (strHref != "")
            strHref += "&";
        strHref += strNewParam;
        if (strNewValue !== undefined && strNewValue != "")
            strHref += "=" + strNewValue;
    }

    return strHref;
}

function jgntLinkBoxes(strParentRule, strHoverClass, strChildRule, objImageBox, strHoverParentClass, strOverFunction, strOutFunction) {

    if (strHoverClass) {
        $(strParentRule).hover(
            function (event) {
                $(this).addClass(strHoverClass);
                if (strOverFunction)
                    eval(strOverFunction);
            },
            function (event) {
                $(this).removeClass(strHoverClass);
                if (strOutFunction)
                    eval(strOutFunction);
            }
        )
    }

    if (strHoverParentClass) {
        $(strParentRule).hover(
            function (event) {
                $(this).parent().addClass(strHoverClass);
            },
            function (event) {
                $(this).parent().removeClass(strHoverClass);
            }
        )
    }

    if (strChildRule) {
        $(strParentRule).not(".jgnt_linkbox_done").addClass("jgnt_linkbox_done").click(function (event) {
            event.preventDefault();
            var chEl = $(this).find(strChildRule)[0];
            if (chEl) {
                if (objImageBox) {
// call gallery box
                } else {
                    childClick = $(chEl).attr('onclick');
                    if (childClick)
                        jQuery.globalEval(childClick);
                    else {
                        childHref = $(chEl).attr('href');
                        if (childHref)
                            if ($(chEl).attr('target') == '_blank')
                                window.open(childHref);
                            else {
                                if (childHref.charAt(0) == '#' && childHref != '#') {
                                    $('html,body').animate({
                                        scrollTop: $(childHref).offset().top - 120
                                    }, 1000);
                                } else
                                    window.location = childHref;
                            }
                    }
                }
            }
        })
    }
}

function jgntSwitch(strSwitchRule) {

    $(strSwitchRule).not('.jgnt_switch_done').addClass('jgnt_switch_done').each(function (index) {

        /*
         if( elObj.hasClass( 'jgnt_switch_hover' ) )
         strEvents = ' mouseenter mouseleave';
         */
        var strEvents = 'click';

        $(this).on(strEvents, function (event) {
            event.preventDefault();

            var elObj = $(this);

            var elTarget = elObj.data('jgnt-target');

            if (elTarget == "parent")
                elTarget = elObj.parent();

            if (!elTarget)
                elTarget = elObj;

            var strClass = elObj.data('jgnt-class');

            if (!strClass)
                strClass = 'jgnt_on';

            var elTurnOff = elObj.data('jgnt-target-off');

            /*
             var elModal = elObj.data('jgnt-modal');
             var elBackdrop = elObj.data('jgnt-backdrop');
             var elBodyClass = elObj.data('jgnt-body-class');

             if( elBodyClass != '' )
             $('body').toggleClass( elBodyClass );

             $( ".jgnt_on" ).not( elTarget ).removeClass( strClass );

             */


            if (elTarget) {
                $(elTarget).toggleClass(strClass);
            }

        })
    })
}

function JGNT_Debug(strMessage) {
    if (JGNT_ConsoleDebug)
        console.log(strMessage);
}

function jgntSetProcessing(elProcess, nHeight) {

    elProcess = $(elProcess);

    if (!nHeight)
        nHeight = elProcess.innerHeight();

    $(elProcess).html($("<div class=\"jgnt_processing\"></div>").height(nHeight));
}

// load more items in catalog
function loadMoreItems($this, $container) {
    $this.addClass('animate');

    $.ajax({
        url: $this.attr('data-ajax-url'),
        type: 'get',
        dataType: $this.attr('data-ajax-datatype'),
        error: function (data) {
            console.log('Error: ', data);
            $this.removeClass('animate');
        },
        success: function (data) {
            $this.removeClass('animate');
            var result = data = typeof data == "string" ? JSON.parse(data) : data;

            if (!$container)
                $this.before(result.html);
            else
                $container.append(result.html);

            if (result.final == 'true')
                $this.hide();

            if (result.next_page != undefined) {
                $this.attr('data-ajax-url', result.next_page);
                $this.attr('href', result.next_page);
            }

        }
    });
}

// history API fallback
function changeHash(_hash) {
    if (history.pushState) {
        history.pushState(null, null, _hash);
    } else {
        location.hash = _hash;
    }
}

// invite popup add button
function popupButtonInit() {
    $('.js-add_email').click(function(){
        var newEmStr = $('.js-invite_form fieldset').html();
        var errorField = $('.js-invite_form fieldset.has-danger');
        $(this).before('<fieldset>' + newEmStr + '</fieldset>');
        jgntValidationConfigure();
        for (i=0;i<errorField.length;i++) {
            errorField[i].className += 'has-danger';
        }
        return false;
    });
}

// height of video-single_content
function heightAsSidebar() {
    setTimeout(function(){
        var leftContentHeight = $('.video-single_content').outerHeight(),
        rightSidebarHeight = $('.video-single_sidebar').outerHeight();
        console.log(leftContentHeight, rightSidebarHeight);
        if ($('.video-single_sidebar').outerHeight() > $('.video-single_content').outerHeight()) {
            $('.video-single_content').outerHeight(rightSidebarHeight + 15);
        } else {
            return false;
        }
    },4000);
}
