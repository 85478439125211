/***********************************************
  MAIN FILE
  - JS dependency imports
  - global updates
  - common functionality
  - header/footer functionality
***********************************************/

/* ! UPDATER -------------------------------------------------- */

function jgntJSUpdate( ){

	jgntLinkBoxes( '.jgnt_linkbox', 'jgnt_hover', 'a.jgnt_linkbox' );

	/* IMPORTS
	***********************************************/

	// calendar
	if( $("#jgntCalendarData").length )
		$LAB.script("/js/calendar.min.js");

	// load bxslider js
	if( JGNT_BannerSliderEl.length || $("#jgntCalendarData").length || $('.bx-slider').length)
		$LAB.script("/js/slider.min.js");

	// custom scroll
	if( $('.custom_scroll').not( ".jgnt_done" ).addClass( "jgnt_done" ).length || $('.jgnt_scroll').not( ".jgnt_done" ).addClass( "jgnt_done" ).length || $('.playlist_list').length )
		$LAB.script("/js/scroll.min.js");


	// nod validation
	if( $('.jgnt_form_validation').length )
		$LAB.script("/js/validation.min.js");


	$('.jgnt_form').not( ".jgnt_form_done" ).addClass( "jgnt_form_done" ).on( 'submit', function(e){
    	var $form = $(this);

		if( $form.data('submitted') === true ){
			// Previously submitted - don't submit again
			e.preventDefault();
		} else {
			if( typeof jgntNod !== 'undefined' ){
				jgntNod.performCheck();

				if( ! jgntNod.areAll('valid') ){
					setTimeout( function(){

                        $( ".has-danger .form-control" ).first().focus();


                        if ($form.data('msg')) {
                            var errorEl = $form.find('[data-msg]');

                            errorEl.each(function() {
                                var errorMsg = $(this).data('msg');

                                $(this).find('.nod-error-message').text(errorMsg);

                            })

                        }

                    }, nod.constants['DELAY'] );
					e.preventDefault();


					$form.addClass('inv-show-error');
				}

			} else
				// Mark it so that the next submit can be ignored
				$form.data( 'submitted', true );
		}
	});

	// touchspin
	if( $('input.touchspin').length )
		$LAB.script("/js/touchspin.min.js");


	// forms processing
	elForm = $(".jgnt_form.buy").not(".jgnt_done").addClass("jgnt_done");
	if( elForm.length ){
		elForm.find(".jgnt_update").on( "change", function(){ jgntFormBuyUpdate( false ) } );
		elForm.find(".jgnt_update_with_items").on( "change", function(){ jgntFormBuyUpdate( true ) } )
		jgntFormBuyUpdate( true );
	}

	elForm = $(".jgnt_form.enrol").not(".jgnt_done").addClass("jgnt_done");
	if( elForm.length ){
		elForm.find("input.custom-control-input").on( "change", function(){ jgntFormEnrolUpdate( ) } );
		jgntFormEnrolUpdate( );
	}

	elForm = $(".jgnt_auth").not(".jgnt_done").addClass("jgnt_done");
	if( elForm.length ){
		elForm.find(".social a").on( "click", function( event ){
			elForm = $(".jgnt_auth");
			elForm.find(".approve .custom-control").on( "click", function(){
				if( elForm.find(".approve .custom-control-input:checked").length ){
					elForm.find(".approve .custom-control").removeClass("has-danger");
					elForm.removeClass("auth-show-error");
				}
			});
			if( ! elForm.find(".approve .custom-control-input:checked").length ){
				event.preventDefault();
				elForm.find(".approve .custom-control").addClass("has-danger");
				elForm.addClass("auth-show-error");
				return false;
			}
		});
	}

	// load blog calendar
	if( $(".blog_calendar").length ) {
		$LAB.script("/js/blogcalendar.min.js");
	}


	// flexy import
	if (!$('html').hasClass('js-injected')) {
		var injections = $('[data-js-inject]').attr('data-js-inject');
		if (injections) {
			$LAB.script(injections.split('__')).wait(function() {
				$('html').addClass('js-injected');
			});
		}
	}

    // sertificate
    if( $("#sertificate-page").length )
        $LAB.script("/js/sertificate.min.js");


	// jgntJSUpdateResize();

	jgntSwitch( ".jgnt_switch" );

	return true;
}

function jgntJSUpdateResize() {

	jgntSameHeight( '.jgnt_sameheight', 'auto' );

	if( $(window).width() >= 960 )
		jgntSameHeight( '.jgnt_sameheight-small' );

	if( $(window).width() >= JGNT_MaxHeightMinWidth )
		jgntSameHeight( '.jgnt_sameheight' );

	if( typeof jgntUpdateSlider != 'undefined' && JGNT_BannerSliderEl.length )
		jgntUpdateSlider();

	return true;
}

function jgntShowSession( event, nID ){

	if( event ){
		event.preventDefault();
		event.stopPropagation();
	}

	if( $("body").hasClass("modal-open") || nID - 0 == 0 )
		return;

	JGNT_Debug( "Opening modal for lesson item with id " + nID );

	var strURL = JGNT_URL_ITEM.replace( "%ID%", nID );
	var elModal = $('#jgntModal');

	elModal.find(".modal-title").html( "" );

	elModal.attr( 'class', 'jgnt_modal modal fade lesson' );

	elModal.modal('show');

	var strContainer = $( '#jgntModal .modal-body' );

	jgntSetProcessing( strContainer );

	JGNT_Debug( "Requesting html from url " + strURL );

	strContainer.load( strURL, function() {

		var elModal = $('#jgntModal');

		elModal.find(".modal-title").html( elModal.find(".jgnt_course_lesson").data("modal-header") );

		// init slider
		elSet = $(".jgnt_picbox");

		if( elSet.length )
			elSet.swipebox({
				selector: '.jgnt_picbox',
				hideBarsDelay: 5000
			});

		var strHref = jgntHrefParse();
		jgntSetHref( jgntHrefMake( strHref, JGNT_HREF_ITEM, nID ) );
		delete strHref[JGNT_HREF_ITEM];
		elModal.data( "jgnt-last-url", jgntHrefMake( strHref ) )

		elModal.modal('_adjustDialog');

	});

}

function jgntShowProgram( event, nID ){

	if( nID == undefined || nID == "" )
		nID = $("#jgntCourse").data("id");

	if( event ){
		event.preventDefault();
		event.stopPropagation();
	}

	if( $("body").hasClass("modal-open") )
		return;

	JGNT_Debug( "Opening modal for course program with id " + nID );

	var strURL = JGNT_URL_PROGRAM.replace( "%ID%", nID );
	var elModal = $('#jgntModal');

	elModal.find(".modal-title").html( "" );

	elModal.attr( 'class', 'jgnt_modal modal fade programm' );

	elModal.modal('show');

	var strContainer = elModal.find( '.modal-body' );

	jgntSetProcessing( strContainer );

	JGNT_Debug( "Requesting html from url " + strURL );

	strContainer.load( strURL, function() {

		var elModal = $('#jgntModal');

		elModal.find(".modal-title").html( elModal.find(".jgnt_course_programm").data("modal-header") );

		var strHref = jgntHrefParse();
		jgntSetHref( jgntHrefMake( strHref, JGNT_HREF_PROGRAM, nID ) );
		delete strHref[JGNT_HREF_PROGRAM];
		elModal.data( "jgnt-last-url", jgntHrefMake( strHref ) )

		elModal.modal('_adjustDialog');

	});

}

function jgntShowAuth( event, strID ){

	if( event ){
		event.preventDefault();
		event.stopPropagation();
	}

	if( $("body").hasClass("modal-open") )
		return;

	JGNT_Debug( "Opening auth popup" );

	var strURL = JGNT_URL_AUTH.replace( "%TXTID%", strID );
	var elModal = $('#jgntModalMiddle');

	elModal.find(".modal-title").html( "" );

	elModal.attr( 'class', 'jgnt_modal_middle modal fade auth' );

	elModal.modal('show');

	var strContainer = elModal.find( '.modal-body' );

	jgntSetProcessing( strContainer );

	JGNT_Debug( "Requesting html from url " + strURL );

	strContainer.load( strURL, function() {

		var elModal = $('#jgntModalMiddle');

		elModal.find(".modal-title").html( elModal.find(".jgnt_auth").data("modal-header") );

		var strHref = jgntHrefParse();
		jgntSetHref( jgntHrefMake( strHref, JGNT_HREF_AUTH ) );
		delete strHref[JGNT_HREF_AUTH];
		elModal.data( "jgnt-last-url", jgntHrefMake( strHref ) )

		elModal.modal('_adjustDialog');

		jgntJSUpdate();

	});
}

function jgntShowInv( event, strID ){

	if( event ){
		event.preventDefault();
		event.stopPropagation();
	}

	if( $("body").hasClass("modal-open") )
		return;
	else {
		$("body").addClass("modal-open middle")
	}

	JGNT_Debug( "Opening auth popup" );

	var strURL = JGNT_URL_INV.replace( "%TXTID%", strID );
	var elModal = $('#jgntModalMiddle');

	elModal.find(".modal-title").html( "" );

	elModal.attr( 'class', 'jgnt_modal_middle modal fade auth' );

	elModal.modal('show');

	var strContainer = elModal.find( '.modal-body' );
	strContainer.addClass('no-padding');

	jgntSetProcessing( strContainer );

	JGNT_Debug( "Requesting html from url " + strURL );

	strContainer.load( strURL, function() {

		var elModal = $('#jgntModalMiddle');

		elModal.find(".modal-title").html( elModal.find(".jgnt_inv").data("modal-header") );

		var strHref = jgntHrefParse();
		jgntSetHref( jgntHrefMake( strHref, JGNT_HREF_INV ) );
		delete strHref[JGNT_HREF_INV];
		elModal.data( "jgnt-last-url", jgntHrefMake( strHref ) )

		elModal.modal('_adjustDialog');

		var currPage = window.location.pathname;
		console.log(currPage);
		elModal.find('form').attr('action', currPage + '#succ');

		jgntJSUpdate();

		popupButtonInit();


	});
	jgntValidationConfigure();

}

function jgntShowSucc( event, strID ){

	if( event ){
		event.preventDefault();
		event.stopPropagation();
	}

	if( $("body").hasClass("modal-open") )
		return;
	else {
		$("body").addClass("modal-open middle")
	}

	JGNT_Debug( "Opening auth popup" );

	var strURL = JGNT_URL_INVSucc.replace( "%TXTID%", strID );
	var elModal = $('#jgntModalMiddle');

	elModal.find(".modal-title").html( "" );

	elModal.attr( 'class', 'jgnt_modal_middle modal fade auth' );

	elModal.modal('show');

	var strContainer = elModal.find( '.modal-body' );
	strContainer.addClass('no-padding');

	jgntSetProcessing( strContainer );

	JGNT_Debug( "Requesting html from url " + strURL );

	strContainer.load( strURL, function() {

		var elModal = $('#jgntModalMiddle');

		elModal.find(".modal-title").html( elModal.find(".jgnt_inv").data("modal-header") );

		var strHref = jgntHrefParse();
		jgntSetHref( jgntHrefMake( strHref, JGNT_HREF_INVSucc ) );
		delete strHref[JGNT_HREF_INVSucc];
		elModal.data( "jgnt-last-url", jgntHrefMake( strHref ) )

		elModal.modal('_adjustDialog');

		jgntJSUpdate();


	});

}

var elMenuSearchBtn = $('.jgnt_header .search a'),
    elMenuSearchDrop = $('.header_search'),
    elMenuSearchInput = elMenuSearchDrop.find('input'),
    elMenuSearchOpen = false;

// init dropdown search
function jgntSearchDropInit(){
	elMenuSearchBtn.on('click', function(event) {
		event.preventDefault();
		event.stopPropagation();
		jgntSearchDropToggle();
	});

	$('.header_search_close').on('click', function(event) {
		event.preventDefault();
		jgntSearchDropToggle('hide');
	});

	// close on click outside
	$('body').on('click', function(event) {
    if (elMenuSearchOpen && !$(event.target).closest('.header_search')[0] && !$(event.target).is('.header_search')) {
      jgntSearchDropToggle('hide');
    }
	});
}

// hide/show dropdown menu
function jgntSearchDropToggle(direction) {
	if (direction === 'hide' || (!direction && elMenuSearchOpen)) {
		if (!elMenuSearchOpen) return;

		elMenuSearchOpen = false;
		elMenuSearchBtn.removeClass('active');
		elMenuSearchDrop.removeClass('header_search--opened');
	} else if (direction === 'show' || !(direction && elMenuSearchOpen))  {
		if (elMenuSearchOpen) return;

		jgntTopMenuOpen();

		elMenuSearchOpen = true;
		elMenuSearchBtn.addClass('active');
		elMenuSearchDrop.addClass('header_search--opened');
        elMenuSearchInput.focus();
	}
}

function jgntTopMenuInit( ){

	// copy menu items to submenu
	elMenuList = $("#jgntTop .nav a");
	elSubmenuItemsCnt = $("#jgntTop .submenu_cnt");
	elSubmenuItems = elSubmenuItemsCnt.find(".item");

	elMenuList.each( function( index ){
		var $this = $(this);
		var submenuIndex = $('.submenu').index($this);
		var elSubItem = submenuIndex > -1 ? elSubmenuItems.eq( submenuIndex ) : [];

		if( elSubItem.length )
			$this.clone().insertBefore( elSubItem );
		else {
			if ($this.hasClass('menu-prepend') && elSubmenuItemsCnt.find('.menu-prepend')[0])
				$this.clone().insertAfter( elSubmenuItemsCnt.find('.menu-prepend:last') );
			else if ($this.hasClass('menu-prepend'))
				$this.clone().prependTo( elSubmenuItemsCnt );
			else
				$this.clone().appendTo( elSubmenuItemsCnt );
		}

	});

	elSubmenuItemsCnt.children('a:last').addClass('last-child');

	elSubmenuItemsCnt.find(".submenu").click( function( event ){ jgntTopMenuOpen( event, this, 1 ) } );
	elSubmenuItemsCnt.find(".menu_close").click( function( event ){ jgntTopMenuOpen( event ) } );

	$("#jgntTop .title").click( function( event ){

		var elTop = $("#jgntTop");

		elTop.toggleClass( "jgnt_on" );
	});
}

function jgntTopMenuOpen( event, el, type ){

	if (event)
		event.preventDefault();

	el = $(el);
	var elSubmenuList = $("#jgntTop .nav .submenu");
	var elSubmenuItemsCnt = $("#jgntTop .submenu_cnt");
	var elSubmenuList2 = elSubmenuItemsCnt.find(".submenu");
	var elSubmenuItems = elSubmenuItemsCnt.find(".item");
	var nIndex;

	if( ! el.length )
		el = elSubmenuItemsCnt.find(".current");

	if( el.hasClass( "current" ) || ! el.length ){

		// close
		elSubmenuList.removeClass( "current" );
		elSubmenuList2.removeClass( "current" );
		if( type === undefined )
			$("#jgntTop").removeClass( "jgnt_on" );
		$("#jgntTop").addClass( "jgnt_all_closed" );
		elSubmenuItems.removeClass("current");

	} else {
		$("#jgntTop").removeClass( "jgnt_all_closed" );

		if( type === undefined )
			nIndex = elSubmenuList.index(el);
		else
			nIndex = elSubmenuList2.index(el);

		// open
		elSubmenuList.removeClass( "current" );
		elSubmenuList2.removeClass( "current" );
		elSubmenuList.eq( nIndex ).addClass( "current" );
		elSubmenuList2.eq( nIndex ).addClass( "current" );
		el.addClass( "current" );
		elSubmenuItems.removeClass("current").eq( nIndex ).addClass("current");

		$("#jgntTop").addClass( "jgnt_on" );
	}

}

function jgntFormEnrolUpdate( ){

	var elForm = $("#jgntFormEnrol");

	elForm.find("input[name=area]").each( function( index ) {
		var el = $(this);
		if( el.is(':checked') ){
			el = elForm.find( "*[data-area=" + $(this).val() + "]" ).show();
			if( ! el.find( "input[name=course]:checked" ).length )
				el.find( "input[name=course]" ).eq(0).prop('checked', true )
		} else
			elForm.find( "*[data-area=" + $(this).val() + "]" ).hide().find( "input[name=course]" ).prop('checked', false );

	});

	elForm.find("input[name=course]").each( function( index ) {
		var el = $(this);
		if( el.is(':checked') ){
			el = elForm.find( "*[data-course=" + $(this).val() + "]" ).show();
			if( ! el.find( "input[name=group]:checked" ).length )
				el.find( "input[name=group]" ).eq(0).prop('checked', true );

			// check if groups list empty – show warning
			if( ! el.length ){
				elForm.find( ".groups" ).hide();
				elForm.find( ".warning" ).show();
				elForm.find( "input[name=form_enrol]" ).val( 2 );
			} else {
				elForm.find( ".groups" ).show();
				elForm.find( ".warning" ).hide();
				elForm.find( "input[name=form_enrol]" ).val( 1 );
			}

		} else
			elForm.find( "*[data-course=" + $(this).val() + "]" ).hide().find( "input[name=group]" ).prop('checked', false );;
	});

	// set price
	el = elForm.find( "input[name=group]:checked" );
	if( el.length ) {
		var $promocode = elForm.find( "fieldset.promocode" ),
			$oldPrice = elForm.find("#jgntTotalOldPrice"),
			$oldPriceBr = elForm.find('br.oldPriceBr'),
			$creditInput = elForm.find('.js-credit-input');


		// elForm.find( "#jgntTotal" ).html( el.data("total") );
		// if (el.data("oldprice")) {
        //     $oldPrice.html(el.data("oldprice")).show();
        //     $oldPriceBr.show();
        // } else {
        //     elForm.find("#jgntTotalOldPrice").html("").hide();
        //     $oldPriceBr.hide();
		// }

		if (el.data("credit")) {
			$creditInput.show();
			$creditInput.find('input').attr('disabled', false);
		} else {
			$creditInput.hide();
            $creditInput.find('input').attr('disabled', 'disabled');
		}

		if (el.data('places-left') > 0) {
            elForm.find( ".groups:not(.groups-list)" ).show();
            elForm.find( ".warning" ).hide();
            elForm.find( "input[name=form_enrol]" ).val( 1 );
		} else {
            elForm.find( ".groups:not(.groups-list)" ).hide();
            elForm.find( ".warning" ).show();
            elForm.find( "input[name=form_enrol]" ).val( 2 );
		}

        // Show custom form by its index or first one by default
        elForm.find( ".wrap.flatlabel.groups" ).hide();
		if ((form_index = el.data('custom-form-index'))) {
			elForm.find( ".wrap.flatlabel.groups" ).eq(form_index).show();
		} else {
			elForm.find( ".wrap.flatlabel.groups" ).eq(0).show();
		}

        if ($.trim($promocode.find( "input[name=promocode]" ).val()) !== "") $promocode.find( ".btn" ).click();
	} else
		elForm.find( "#jgntTotal" ).html( "" );

	if( typeof jgntValidationConfigure == 'function' )
		jgntValidationConfigure();
}

function jgntFormBuyUpdate( nRefreshItemsList ){

	var elForm = $("#jgntFormBuy");

	elForm.find("#jgntSertRemove, #jgntSertRemoveItems").not(".jgnt_done").add("jgnt_done").click( function(){
		elForm.find("input[name=sert_id_approved]").val( 0 );
		elForm.find(".block_sert_form").show();
		elForm.find(".block_sert_info").hide();
		jgntFormBuyUpdate( 1 );
	});

	elForm.find("#jgntSertCheck").not(".jgnt_done").add("jgnt_done").click( function(){
		// check sert
		var elSertForm = elForm.find(".block_sert_form");
		var elSertInfo = elForm.find(".block_sert_info");
		var elSertError = elForm.find(".block_sert_error");
		var elSertProcessing = elForm.find(".block_sert_processing");

		var strParams = {};
		strParams["sert_id"] =  elForm.find("input[name=sert_id]").val();

		elForm.find("input[name=sert_id_approved]").val( 0 );

		elSertForm.hide();
		elSertInfo.hide();
		elSertError.hide();
		jgntSetProcessing( elSertProcessing );

		JGNT_Debug( "Requesting sertificate " + strParams["sert_id"] + " status..." );

		$.post( JGNT_URL_FORM_SERT_CHECK, strParams, function( data ) {
			elSertProcessing.hide();
			if( data.substring( 0, 8 ) == "!!!OK!!!" ){
				elForm.find("input[name=sert_id_approved]").val( 1 );
				elSertInfo.find(".total").html( data.substring( 8 ) );
				elSertInfo.show();
			} else {
				elSertForm.show();
				elSertError.html( data ).show();
			}
			jgntJSUpdate();
			jgntFormBuyUpdate( 1 );
		});

	});

	// check sert state
	if( elForm.find("input[name=sert_id_approved]").val() == 1 ){
		elForm.find(".block_sert_form").hide();
		elForm.find(".block_sert .sert").hide();
		elForm.find(".block_sert_info").show();
	} else {
		elForm.find(".block_sert_form").show();
		elForm.find(".block_sert .sert").show();
		elForm.find(".block_sert_info").hide();
	}

	var nAmount = elForm.find("input[name=amount]").val();
	var nType = elForm.find("input[name=ticket_shipment]:checked").val();

	if( nType == 1 ){
		elForm.find(".block_friend").hide();
		elForm.find(".block_sert").show();
	} else {
		elForm.find(".block_friend").show();
		elForm.find(".block_sert").hide();
	}

	if( nAmount > 1 && nType == 1 ){
		elForm.find(".block_guest_list").show();
		if( elForm.find("input[name=add_guest_list]:checked").length ){

			var elGuestList = elForm.find(".guest_list");
			var nGuestAmount = nAmount - 1;

			// guest list adjust
			var elGuestListItems = elGuestList.find( ".guest_list_item" );

			elGuestListItems.show();

			if( elGuestListItems.length > nGuestAmount ){
				// hide blocks in the end
				elGuestListItems.slice( nGuestAmount - elGuestListItems.length ).hide();
			}

			if( elGuestListItems.length < nGuestAmount ){

				// add new blocks
				for( i = 0; i < nGuestAmount - elGuestListItems.length; i++ ){
					var elItem = elGuestListItems.eq( 0 ).clone();
					var nItemNum = i + elGuestListItems.length + 1;
					elItem.find(".num").html( nItemNum );
					elItem.find("input[name=guest_1_name]").val("").attr( "name", "guest_" + nItemNum + "_name" );
					elItem.find("input[name=guest_1_email]").val("").attr( "name", "guest_" + nItemNum + "_email" );
					elGuestList.append( elItem );
				}

			}

			elGuestList.show();

		} else {
			elForm.find(".guest_list").hide();
		}

	} else {
		elForm.find(".block_guest_list").hide();

	}

	// gift
	if( elForm.find("input[name=gift_from_check]:checked").length )
		elForm.find(".gift_from").show();
	else
		elForm.find(".gift_from").hide();

	if( elForm.find("input[name=gift_adr_check]:checked").length )
		elForm.find(".gift_address").show();
	else
		elForm.find(".gift_address").hide();


	if( nRefreshItemsList ){

		// load items list
		elReload = elForm.find(".inorder");
		strParams = elForm.find("form").serializeArray();

		jgntSetProcessing( elReload );

		JGNT_Debug( "Requesting items list..." );

		elReload.load( JGNT_URL_FORM_BUY_ITEMS, strParams, function() { jgntFormBuyUpdate(); jgntJSUpdate() } );

	}

	if( typeof jgntValidationConfigure == 'function' )
		jgntValidationConfigure();
}

function jgntProfileProcess( strAction ){
	el = $(".jgnt_profile");

	if( strAction == "init" ){
		JGNT_Debug( "Profile init..." );

		el.find(".info .edit").not(".jgnt_done").addClass("jgnt_done").click( function() { jgntProfileProcess( "form" ) } );
		el.find(".jgnt_btn.cancel").not(".jgnt_done").addClass("jgnt_done").click( function(){
			$(".jgnt_profile").find(".message").hide();
			jgntProfileProcess( "info" );
		});
		el.find(".jgnt_btn").not(".jgnt_done").addClass("jgnt_done").click( function() { jgntProfileProcess( "submit" ) } );


	} else if( strAction == "form" ){
		JGNT_Debug( "Profile form load..." );

		jgntSetProcessing( el.find(".info") );

		var strParams = "";

		el.find(".jgnt_form").load( JGNT_URL_PROFILE_FORM, strParams, function(){
			el.addClass("form");
			jgntProfileProcess( "init" );
			jgntJSUpdate();
		});

	} else if( strAction == "info" ){
		JGNT_Debug( "Profile info load..." );

		el.find(".message").hide();

		jgntSetProcessing( el.find(".jgnt_form") );

		var strParams = "";

		el.find(".info").load( JGNT_URL_PROFILE_INFO, strParams, function(){
			$(".jgnt_profile").removeClass("form");
			jgntProfileProcess( "init" );
			jgntJSUpdate();
		});

	} else if( strAction == "done" ){
		JGNT_Debug( "Profile done info load..." );

		jgntSetProcessing( el.find(".jgnt_form") );

		var strParams = "";

		el.find(".info").load( JGNT_URL_PROFILE_INFO, strParams, function(){
			$(".jgnt_profile").find(".message").show();
			$(".jgnt_profile").removeClass("form");
			jgntProfileProcess( "init" );
			jgntJSUpdate();
		});

	} else if( strAction == "submit" ){
		JGNT_Debug( "Profile form submit..." );

		event.preventDefault();

		var strParams = el.find("form").serializeArray();

		jgntSetProcessing( el.find(".jgnt_form") );

		$.post( JGNT_URL_PROFILE_FORM, strParams, function( data ) {
			if( data.substring( 0, 8 ) == "!!!OK!!!" ){
				jgntProfileProcess( "done" );
			} else {
				$(".jgnt_profile").find(".jgnt_form").html( data );
			}
			jgntProfileProcess( "init" );
			jgntJSUpdate();
		});

		return false;
	}

}

/* ! INIT -------------------------------------------------- */
$window.on('load', function() {
	jgntJSUpdateResize();
});



(function ($) {
	var truncate = function (el) {
		$('.js-dots__txt').css('height', 'auto');
		$('.js-dots').each(function () {
			var height = $(this).height();
			$(this).find('.js-dots__h').each(function () {
				height = height - $(this).outerHeight(true);
			});
			$(this).removeClass('act').find('.js-dots__txt').css('height', height);
		});

		var line = parseInt(el.css('line-height')),
			line_clamp = el.height() / line;
		line_clamp = Math.floor(line_clamp);
		var elHeight = line * line_clamp;
		el.find('div').css({ 'height': elHeight, '-webkit-line-clamp': line_clamp.toString()})
	};

	$.fn.truncateText = function () {
		return this.each(function () {
			truncate($(this));
		});
	};
}(jQuery));


$(document).ready(function() {

	// content height
	heightAsSidebar();

	// get href params
	var JGNT_HrefParam = jgntHrefParse();

	$("#jgntTop").headroom({
		"offset": 110,
		"tolerance": 5,
		onUnpin: function() {
			if ($("#jgntTop .submenu.current").length)
				jgntTopMenuOpen( false, $("#jgntTop .submenu.current")[0] );
		}
	});

    // add top mouse open headroom
	$( document ).mousemove(function( e ) {

		if( e.clientY < 90 && ! $("body").hasClass("modal-open") && ! $(".jgnt_header").hasClass("jgnt_menu_on") )
			$("#jgntTop").removeClass( "headroom--unpinned" ).addClass( "headroom--pinned" );
/*
		if( e.pageY < 40 )
			$(".jgnt_header").removeClass( "headroom--not-top" ).addClass( "headroom--top" );
*/
	});

	$("#jgntTop .submenu").click( function( event ){ jgntTopMenuOpen( event, this ) } );

	jgntSearchDropInit();

	jgntTopMenuInit();

	JGNT_BannerSliderEl = $("#jgntTopSlider");

	jgntJSUpdate();

	$(window).resize(function() {
		jgntJSUpdateResize();
	});

	// check popups

	if( jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_AUTH ) )
		jgntShowAuth( false, "auth" );
	else if( jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_INV ) )
		jgntShowInv( false, "inv" );
	else if( jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_INVSucc ) )
		jgntShowSucc( false, "succ" );
	else if ( $("#jgntCourse").hasClass( "popup" ) || jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_PROGRAM ) )
		jgntShowProgram( false, jgntHrefGetParam( JGNT_HrefParam, JGNT_HREF_PROGRAM ) );
	else if( jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_ITEM ) )
		jgntShowSession( false, jgntHrefGetParam( JGNT_HrefParam, JGNT_HREF_ITEM ) );
	else if( $("body").hasClass( "popupsession" ) )
		jgntShowSession( false, $("body").data("session-id") );
	else if( jgntHrefCheckParam( JGNT_HrefParam, JGNT_HREF_CALENDAR_BIG ) )
		jgntCalendarPopupBig( jgntHrefGetParam( JGNT_HrefParam, JGNT_HREF_CALENDAR_BIG ) );

	$('.modal').on('shown.bs.modal', function (e) {

		var strPad = $("body").css('padding-right');
		if( strPad != "0px" ) {
			$(".jgnt_top").css('right', strPad );
			$(".modal-header").css('right', strPad );
		}

		if( $(this).hasClass("jgnt_modal_middle") )
			$("body").addClass("middle");
		else
			$("body").removeClass("middle");

		if( $(this).hasClass("jgnt_modal_system") )
			$("body").addClass("system");
		else
			$("body").removeClass("system");

	}).on('hide.bs.modal', function (e) {
		var el = $('#jgntModal');

		jgntSetHref( el.data("jgnt-last-url") );
		el.data( "jgnt-last-url", "" );

		$(".jgnt_top").css('right', "0" );
		$(".modal-header").css('right', "0" );

	}).on('keyup', function (e) {

		if ( e.keyCode === 27 &&  $("body").hasClass("modal-open") ) {
			if( ! $.swipebox.isOpen )
				$('#jgntModal').modal('hide');
		}

	})

	jgntProfileProcess( "init" );

	// init gallery
	var galItems = $(".custom_picbox");

	if( galItems.length )
		galItems.swipebox({
			selector: '.custom_picbox',
			hideBarsDelay: 5000
		});

	// show more tags
	$(document).on('click', '.tags_more', function(event) {
		event.preventDefault();
		$(this).parent('.tags').addClass('tags--all');
	});

	// show more photos
	$(document).on('click', '[data-hidden-more-button]', function(event) {
		event.preventDefault();
		$('[data-hidden-more-parent='+ $(this).data('hidden-more-button') +']').addClass('show-hidden');
	});

	// load more items in catalog
  $('.catalog_more').on('click', function(event) {
    event.preventDefault();
    var container = !$(this).data('ajax-container') ? false : $($(this).data('ajax-container'));
    loadMoreItems($(this), container );
  });

  // default form validation
  if ($.fn.validate) {

    $('.form--validate').each(function() {
    	var settings = {
    		ignore: '.ignore',
            errorPlacement: function(error, element) {
                if (element.attr("name") === "party[termsAccepted]" || element.attr("name") === "franchise[termsAccepted]" ) {
                    element.parent('label').after(error);
                } else {
                    element.addClass('error');
                    element.after(error);
                }
            },
    	};

    	if ($(this).attr('data-sending-type') == 'ajax') {
        settings.submitHandler = function(form, event) {

	      	event.preventDefault();
            var $submit = $(form).find('button[type=submit]');
	      	$submit.attr('disabled', 'disabled');

	        // AJAX
	        var formData = $(form).serializeArray(),
	            url = $(form).attr('action') ? $(form).attr('action') : '',
	            dataType = $(form).attr('data-ajax-data-type') ? $(form).attr('data-ajax-data-type') : undefined;
	        $.ajax({
	          type: 'POST',
	          url: url,
	          data: formData,
	          dataType: dataType,
	          success: function() {
	            setTimeout(function() {
	              $(form).removeClass('form--error');
	              $(form).addClass('form--success');
				  $(form).find('input[type=text],input[type=email],textarea').val('');
	            },1);

	            setTimeout(function() {
	              $(form).removeClass('form--success');
	            },5000);

	            if ($(form).data("school_party")) {
	              //ga('send', 'event', 'schoolPartyRequestSend');
	            }
	          },
	          error: function(response) {
	            console.log('Sending error: ',response);
	            setTimeout(function() {
	              $(form).removeClass('form--success');
	              $(form).addClass('form--error');
	            },1);
	          }
	        }).then(function(){
                $submit.attr('disabled', false);
			});
        }
      }

      $(this).validate(settings);
    })
  };

	// open blog rubricator
  $('.blog_toggler').on('click', function(event) {
    event.preventDefault();
    if ($('.blog_sidebar--opened').length) {
    	$('.blog_sidebar').removeClass('blog_sidebar--opened');
    } else {
    	$('.blog_sidebar').addClass('blog_sidebar--opened');
    }
  });

  // bind scroll button events
  var $scrollTopButton = $('.scroll-top-btn'),
      scrollTopTimeout;
  $window.on('scroll', function() {
  	clearTimeout(scrollTopTimeout);
  	scrollTopTimeout = setTimeout(function() {
  		if ($window.scrollTop() > $window.height()) {
  			$scrollTopButton.addClass('scroll-top-btn--visible');
  		} else {
  			$scrollTopButton.removeClass('scroll-top-btn--visible');
  		}
  	}, 100);
  });

  $scrollTopButton.on('click', function() {
  	$('html, body').animate({ 'scrollTop': 0 });
  });

  	$(document).on('click', '.js-open-modal', function() {
		jgntShowSession(false, $(this).data("session-id"));
	});


	// open menu more
	$(document).on('click', '.js-nav-more-open', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('.js-nav-submenu').fadeToggle()
	});
    $(document).on('click', '.js-nav-more-open2', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.js-nav-submenu2').fadeToggle()
    });

	// opening a mobile profile
	$(document).on('click', '.js-user-submenu', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('.head-user-submenu').fadeToggle();
		$('body').toggleClass('right-open');
	});
	$(document).on('click', '.head-user-menu__close, .head-user-menu__exite', function (e) {
		e.preventDefault();
		$(this).removeClass('active');
		$('.head-user-submenu').fadeOut();
		$('body').removeClass('right-open');
	});

	// opening a mobile menu
	$(document).on('click', '.js-burger-head', function (e) {
		e.preventDefault();
		$(this).toggleClass('active');
		$('body').toggleClass('left-open');
	});
	$(document).on('click', '.js-heade-menu-mob__close', function (e) {
		e.preventDefault();
		$(this).removeClass('active');
		$('body').removeClass('left-open');
	});


	$(document).mouseup(function (e) {
		var container = $(".js-nav-more-open, .js-nav-submenu");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			$('.js-nav-more-open').removeClass('active');
			$('.js-nav-submenu').fadeOut()
		};
        var container2 = $(".js-nav-more-open2, .js-nav-submenu2");
        if (!container2.is(e.target) && container2.has(e.target).length === 0) {
            $('.js-nav-more-open2').removeClass('active');
            $('.js-nav-submenu2').fadeOut()
        };
		var container3 = $(".js-user-submenu, .head-user-submenu");
		if (!container3.is(e.target) && container3.has(e.target).length === 0) {
			$('.js-user-submenu').removeClass('active');
			$('.head-user-submenu').fadeOut()
		};
	});

	// menu fix
	if ($('.head-img').length) {
		$('.jgnt_header').addClass('jgnt_header--fix');
	};

	// height el
	$('.js-dots__txt').truncateText();
	$(window).resize(function () {
		setTimeout(function () {
			$('.js-dots__txt').truncateText()
		}, 500);
	});



	if($('.b-section--culinat-curator').length) {
		$('body').addClass('head-opacity');
	}

	$(document).on('click', '#swipebox-close', function (e) {
		e.preventDefault();
	});

	/*======== Вывод отзывов ========*/
	var $rootOther = $('.js-slider-other'),
		$rootModal = $('.b-school__other__modal'),
		$parent = $rootOther.parents('[data-other-parent]'),
		ajaxPage = 0;
	if ($rootOther.length) {
		ajaxOther(ajaxPage)
	};
	$(document).on('click', '.b-school__other-hide-all a', function (e) {
		e.preventDefault();
		ajaxPage = ++ajaxPage;
		ajaxOther(ajaxPage)
	});
	function ajaxOther(ajaxPage) {
		var ajaxUrl = $rootOther.data('other-url'),
			ajaxPost = $rootOther.data('other-type');

		$.ajax({
			type: ajaxPost,
			url: ajaxUrl,
			data: { 'page': ajaxPage },
			dataType: 'json',
			success: function (data) {
				if (typeof data.items == 'object' && data.items.length ) {
					$parent.removeClass('hidden-xs-up');
				} else {
					return;
				}
				insertOther(data.items)
				if (data.stop) {
					$('.b-school__other-hide-all').hide();
				}
			}
		})
	}
	function insertOther(data){
		var listOption = '',
			listOther = '';
		for (var i = 0; i < data.length; i++) {
			item = data[i];
			listOption += '<div class="b-school__other-item active">'+
				'<div class="b-school__other-wrap">'+
					'<div class="b-school__other-txt">'+
						'<div class="b-school__other-p js-b-school__other slick-slider">' + item.caption +'</div>'+
						'<a href="#" data-toggle="modal" data-target="#other-' + item.id +'" class="b-school__other-open">Читать полностью</a>'+
						'<div class="b-school__other-name">' + item.name +'</div>'+
						(item.work != "" ? '<div class="b-school__other-detals">' + item.work +'</div>':'')+
						((item.course_name != ""  && item.course_link != "") ?
                    		'<div class="b-school__other-detals">'+
                    		'<p>О курсе <a href="'+item.course_link+'">' + item.course_name +'</a></p>'+
                    		'</div>' : '')+
					'</div>'+
				'</div>'+
			'</div>';
			listOther += '<div class="modal fade" id="other-' + item.id +'" style="background-color: rgba(255,255,255,.85)" ' +
				'tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">'+
				'<button type="button" class="close" data-dismiss="modal" aria-label="Close">'+
					'Закрыть окно <span aria-hidden="true">&times;</span>'+
				'</button>'+
				'<div class="modal-dialog" role="document">'+
					'<div class="modal-content">'+
						'<div class="b-popup__other">'+
							'<div class="b-popup__other-head">'+
								'<div class="b-popup__other-txt">'+
									'<div class="b-popup__other-txt__name">' + item.name +'</div>'+
                					(item.work != "" ? '<div class="b-popup__other-txt__detals">' + item.work +'</div>' : '')+
									((item.course_name != ""  && item.course_link != "") ?
										'<div class="b-popup__other-body">'+
										'<p>О курсе <a href="'+item.course_link+'">' + item.course_name +'</a></p>'+
										'</div>' : '')+
								'</div>'+
							'</div>'+
							'<div class="b-popup__other-body">'+
								'<p>' + item.caption +'</p>'+
							'</div>'+
						'</div>'+
					'</div>'+
				'</div>'+
			'</div>';
		};
		$rootOther.append(listOption);
		$rootModal.append(listOther);

		$('.b-school__other-item.active').each(function () {
			var $el = $(this).find('.js-b-school__other');
			if ($el.height() > 20) {
				$el.addClass('b-school__other-p--hide');
			};
			$(this).removeClass('active');
		});

		otherSlide()

	};


	var sliderOtherSettings = {
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		rows: 0,
		prevArrow: '<a href="javascript:void(0);" class="b-event-slider__arrow b-event-slider__arrow--prev"></a>',
		nextArrow: '<a href="javascript:void(0);" class="b-event-slider__arrow b-event-slider__arrow--next"></a>',
		variableWidth: true,
		arrows: true,
		responsive: [{
			breakpoint: 667,
			settings: {
				infinite: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				rows: 0,
				arrows: true,
				variableWidth: true,
				prevArrow: '<a href="javascript:void(0);" class="b-event-slider__arrow b-event-slider__arrow--prev"></a>',
				nextArrow: '<a href="javascript:void(0);" class="b-event-slider__arrow b-event-slider__arrow--next"></a>'
			}
		}]
	};

	var $sliderOther = $('.js-slider-other');
	function otherSlide() {
		var windWidth = $(window).width();
		if (windWidth < 650) {
			if (!$sliderOther.hasClass('slick-slider')) {
				$sliderOther.slick(sliderOtherSettings);
			}
		} else {
			if ($sliderOther.hasClass('slick-slider')) {
				$sliderOther.slick('unslick')
			}
		}
	}
	if ($sliderOther.length) {
		$window.on('resize', function () {
			otherSlide();
		})
	};





});
