/***********************************************
 Training pages funtionality
 ***********************************************/
(function () {

  /* Trainings functional
   ***********************************************/
    var trainingService = (function () {
        var root = {},
            $rootEl = $('.training'),
            ajaxUrls = {
                list: $rootEl.attr('data-training-list-url'),
                item: $rootEl.attr('data-training-item-url')
            },
            $listContainer = $('.training_list'),
            $listScroller = $('.training_list_wrapper'),
            $popup = $('.training_popup'),
            $popupContainer = $('.training_popup_content'),
            $prevLink = $('.training_popup_prev'),
            $nextLink = $('.training_popup_next'),
            $courseLinks = $('[data-training-get-list]'),
            $calendar = $('.addeventstc'),
            animateInTimeout = false,
            animateOutTimeout = false;

        root.popupOpened = false;

        function insertList() {
            var content = '', i, j, cats;

            for (i = 0; i < root.list.items.length; i++) {
                // add categories
                cats = '';
                for (j = 0; j < root.list.items[i].cats.length; j++) {
                    if (root.list.items[i].cats[j] === 'video') {
                        cats += '<span class="training_list_item_cat training_list_item_cat--' + root.list.items[i].cats[j] + '">Видео</span>';
                    } else if (root.list.items[i].cats[j] === 'homework') {
                        cats += '<a href="#" class="training_list_item_cat training_list_item_cat--' + root.list.items[i].cats[j] + '"><span>Домашнее задание</span></a>';
                    }
                }

                // add all markup
                content += '<div class="training_list_item training_list_item--' + root.list.items[i].state + '" data-training-get="' + root.list.items[i].id + '"><div class="training_list_item_num"><span>' + root.list.items[i].oid + '</span></div><div class="training_list_item_content"><div class="training_list_item_date' + (root.list.items[i].state == 'done' ? ' training_list_item_date--done' : '') + '">' + root.list.items[i].date + '</div><h2 class="training_list_item_title"><a href="">' + root.list.items[i].name + '</a></h2><div class="training_list_item_cats">' + cats + '</div></div></div>';
            }

            $listContainer.html(content);

            // update calendar id
            $calendar.attr('data-id', root.list.calendar);

            // update scroll
            $listScroller.mCustomScrollbar('scrollTo', 'top', {
                scrollInertia: 0
            });

            // apply appear animation
            setTimeout(function () {
                $listContainer.removeClass('training_list--animate-out').addClass('training_list--animate-in');
            }, 0);

            if (animateInTimeout) clearTimeout(animateInTimeout);
            animateInTimeout = setTimeout(function () {
                $listContainer.removeClass('training_list--animate-in');
            }, 900);
        };

        function isertTraining() {
            var content = '', childs = [], i, j, k, index;

            // add training title and number
            content += '<div class="training_item_header"><div class="training_item_num">' + root.item.oid + '</div><h2 class="training_item_title">' + root.item.name + '</h2>';

            // add description
            if (root.item.announce != null) {
                content += '<div class="training_item_tags">';
                content += root.item.announce;
                content += '</div>';
            }

            // add child tabs
            if (root.item.childs.length) {
                childs = root.item.childs;

                content += '<div class="training_item_nav">';
                for (j = 0; j < childs.length; j++) {
                    content += '<a class="training_item_nav_link' + (j === 0 ? ' training_item_nav_link--active' : '') + '" href="" data-training-tab-link="' + j + '">' + childs[j].name + '</a>';
                }
                content += '</div></div><div class="training_item_body">';
            } else {
                childs.push(root.item);
                content += '</div><div class="training_item_body">';
            }

            // add childs'/own content
            for (k = 0; k < childs.length; k++) {
                content += '<div class="training_item_tab' + (k === 0 ? ' training_item_tab--active' : '') + '" data-training-tab="' + k + '">';

                // add control panel
                content += '<div class="training_item_controls">';
                if (childs[k].video_url !== 'false') {
                    content += '<a href="' + childs[k].video_url + '" class="training_item_video">Смотреть видеоурок</a>';
                }
                content += '<a href="" class="training_item_print">Распечатать</a></div>';

                // add ingridient groups
                if (childs[k].ingridients !== 'false' && childs[k].ingridients.length) {
                    content += '<div class="training_item_ingridients"><div class="training_item_ingridients_title">Ингредиенты</div>';
                    for (i = 0; i < childs[k].ingridients.length; i++) {
                        content += '<div class="training_item_ingridients_group">';

                        // if group have name
                        if (childs[k].ingridients[i].group_name !== 'false') {
                            content += '<div class="training_item_ingridients_group-title">' + childs[k].ingridients[i].group_name + '</div>'
                        }

                        for (j = 0; j < childs[k].ingridients[i].group_items.length; j++) {
                            content += '<div class="training_item_ingridients_group-row"><div class="training_item_ingridients_group-name">' + childs[k].ingridients[i].group_items[j].name + '</div><div class="training_item_ingridients_group-value">' + childs[k].ingridients[i].group_items[j].value + '</div></div>';
                        }

                        // close group
                        content += '</div>';
                    }

                    // close ingridients
                    content += '</div>';
                }

                // add recipe
                if (childs[k].recipe !== 'false') {
                    content += '<div class="training_item_recipe"><div class="training_item_recipe_title">Приготовление:</div><div class="training_item_recipe_content">' + childs[k].recipe + '</div></div></div>';
                }
            }

            // close training body
            content += '</div>';

            // prev and next links
            index = root.itemsIds.indexOf(root.item.id);
            if (index > 0) {
                $prevLink.attr('data-training-get', root.itemsIds[index - 1]);
                $prevLink.removeClass('disabled');
            } else {
                $prevLink.addClass('disabled');
            }

            if (index < root.itemsIds.length - 1) {
                $nextLink.attr('data-training-get', root.itemsIds[index + 1]);
                $nextLink.removeClass('disabled');
            } else {
                $nextLink.addClass('disabled');
            }

            $popupContainer.html(content).fadeIn(300);
        };

        function setActiveTrainingLink(_id) {
            var $link = $('[data-training-get-list=' + _id + ']'),
                $parent = $link.closest('.training_sidebar_item');
            $('.training_sidebar_item_title_link--active').removeClass('training_sidebar_item_title_link--active');
            $('.training_sidebar_item--active').removeClass('training_sidebar_item--active');
            $link.addClass('training_sidebar_item_title_link--active');
            $parent.addClass('training_sidebar_item--active');

            $('.training_sidebar_mobile-title').html($parent.html());
        }

        root.getTrainingList = function (_id, _return) {
            if (!_return && typeof root.currentListId !== 'undefined' && root.currentListId === _id) return;

            $.ajax({
                type: 'GET',
                url: ajaxUrls.list,
                data: {'id': _id},
                dataType: 'json',
                success: function (data) {
                    root.list = typeof data == "string" ? JSON.parse(data) : data;
                    setActiveTrainingLink(_id);

                    $popup.hide();
                    $rootEl.removeClass('training--show-popup');
                    root.currentListId = _id;
                    changeHash('#/training-list-' + _id);

                    if ($('.training_list_item').length && !root.popupOpened) {
                        $listContainer.addClass('training_list--animate-out');

                        if (animateOutTimeout) clearTimeout(animateOutTimeout);
                        animateOutTimeout = setTimeout(function () {
                            insertList();
                        }, 900);
                    } else {
                        insertList();
                    }

                    root.popupOpened = false;
                },
                error: function (data) {
                    console.log('Server error');
                }
            });
        };

        root.getTraining = function (_id) {
            function onScrollTop() {
                if (root.popupOpened) {
                    $popupContainer.fadeOut(300, isertTraining);
                } else {
                    isertTraining();
                    $popup.show();
                    setTimeout(function () {
                        $rootEl.addClass('training--show-popup');
                    }, 20);
                }

                root.popupOpened = true;
                changeHash('#/training-list-' + root.currentListId + '/training-' + _id);
            };

            $.ajax({
                type: 'GET',
                url: ajaxUrls.item,
                data: {'id': _id},
                dataType: 'json',
                success: function (data) {
                    root.itemData = typeof data == "string" ? JSON.parse(data) : data;
                    root.itemsIds = root.itemData.group_ids;
                    root.item = root.itemData.info;

                    if ($window.scrollTop() > 0) {
                        $('html, body').animate({scrollTop: 0}, 300).promise().then(onScrollTop);
                    } else {
                        onScrollTop();
                    }
                },
                error: function (data) {
                    console.log('Server error');
                }
            });
        };

        // initializations
        $window.on('load', function () {
            var hash = window.location.hash,
                id;
            if (hash.search(/training-list-(.*?)\/training-/) > -1) {
                root.currentListId = hash.replace(/#\/training-list-(.*?)\/training-(.*)/g, '$1');
                id = hash.replace(/#\/training-list-(.*?)\/training-(.*)/g, '$2');
                setActiveTrainingLink(root.currentListId);
                root.getTraining(id);
            } else if (hash.search('training-list-') > -1) {
                id = hash.replace(/#\/training-list-(.*)/g, '$1');
                root.getTrainingList(id);
            } else if ($('[data-training-list-url]')[0]) {
                root.getTrainingList($courseLinks.eq(0).attr('data-training-get-list'));
            }
        });

        $(document).on('click', '[data-training-get-list]', function (event) {
            event.preventDefault();
            if ($(this).attr('data-training-get-list') === 'false') return;

            $('.training_sidebar').removeClass('training_sidebar--open-menu');
            root.getTrainingList($(this).attr('data-training-get-list'));
        });

        $(document).on('click', '[data-training-get]', function (event) {
            event.preventDefault();
            if ($(this).hasClass('disabled')) return;
            root.getTraining($(this).attr('data-training-get'));
        });

        $(document).on('click', '[data-training-tab-link]', function (event) {
            event.preventDefault();
            $('.training_item_nav_link--active').removeClass('training_item_nav_link--active');
            $('.training_item_tab--active').removeClass('training_item_tab--active');

            $(this).addClass('training_item_nav_link--active');
            $('[data-training-tab=' + $(this).attr('data-training-tab-link') + ']').addClass('training_item_tab--active');
        });

        $(document).on('click', '.training_popup_close', function (event) {
            event.preventDefault();
            root.getTrainingList(root.currentListId, true);
        });

        $(document).on('click', '.training_item_print', function (event) {
            event.preventDefault();
            window.print();
        });

        $('.training_sidebar_mobile-title').on('click', function (event) {
            event.preventDefault();
            if ($('.training_sidebar').hasClass('training_sidebar--open-menu')) {
                $('.training_sidebar').removeClass('training_sidebar--open-menu');
            } else {
                $('.training_sidebar').addClass('training_sidebar--open-menu');
            }
        });

        return root;
    })();
})();