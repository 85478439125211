// Make all ajax requests CSRF-protected
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Knockout setup
{
    ko.bindingHandlers.inputmask = {
        init: function (element, valueAccessor) {
            const mask = valueAccessor(), observable = mask.value;

            if (ko.isObservable(observable)) {
                $(element).on('focusout change', function () {
                    if ($(element).inputmask('isComplete')) {
                        observable($(element).val());
                    } else {
                        observable(null);
                    }
                });
            }

            $(element).inputmask(mask);
        },
        update: function (element, valueAccessor) {
            const mask = valueAccessor(), observable = mask.value;

            if (ko.isObservable(observable)) {
                $(element).val(observable());
            }
        }
    };

    ko.bindingHandlers.initializeValue = {
        init: function (element, valueAccessor) {
            valueAccessor()(element.value);
        }
    };
}
// /Knockout setup

$(".party_info_gallery").addClass("_ready");

$(function() {
    var $window = $(window),
        $body = $("body");

    $('.menu-logout').on('click', function (e) {
        e.preventDefault();
        $.ajax({
            url: '/logout',
            type: 'POST',
            success: function() {
                window.location = '/';
            },
            error: function() {
                window.location = '/';
            }
        });
    });
    
    // Buy course popup
    (function() {
        var $data_obj = $(".js-buy-tariff"),
            check_timeout = null,
            ready_send = true,
			tariffId = $data_obj.data("tariff-id");
        
        $body.on("click", ".yandex-buy", function(e) {
            e.preventDefault();
        
            if (!$(".buy_course_form:visible").length) {
                var $elModal = $('#jgntModalMiddle');
                
                $elModal.find(".modal-title").html("Покупка");
                $elModal.attr('class', 'jgnt_modal_middle modal fade');
                $elModal.modal('show');
                $elModal.find('.modal-body').removeClass("no-padding").html('<form action="#" class="jgnt_form buy_course_form"><fieldset><div class="buy_course_form_title">' + $(".ribbon--title").html() + '</div><p class="buy_course_form_price">' + $(".video-message_text").html() + ' <span class="buy_course_form_price_value" data-val="' + $(".video-message_value").html().replace(/ /g, "").replace(/<span.*>/g, "") + '">' + $(".video-message_value").html() + '</span></p><label>У меня есть промо-код:</label><input type="text" class="form-control short"></fieldset><button class="jgnt_btn sml" type="button">Оплатить</button></form>');
            }
        });
    
        $body.on("submit", ".buy_course_form", function (e) {
            e.preventDefault();
            var promocode = $("input", this).val();
    
            if ($("fieldset", this).hasClass("has-danger")) {
                return false;
            }
            if (ready_send) {
                ready_send = false;
        
                $.ajax({
                    type: 'POST',
                    url: "/order",
                    data: {
                        tariffId: $data_obj.data("tariff-id"),
                        masterclassId: $data_obj.data("masterclass-id"),
                        email: window.authorizedSession.email,
                        promoCode: promocode
                    },
                    dataType: undefined,
                    success: function(e) {
                        $("#paymentForm").remove();

                        if (e.form !== undefined) {
                            $body.append(e.form);
                            $("#paymentForm").submit();
                        }

                        if (e.status === 1 && e.url !== undefined) {
                            window.location.href = e.url;
                        }
                    },
                    error: function(e) {
                        ready_send = true;
                        $(".buy_course_form fieldset").addClass("has-danger");
                    }
                });
            }
        });
    
        $body.on("click", ".buy_course_form .jgnt_btn", function () {
            $(".buy_course_form").submit();
        });
    
        $body.on("keyup", ".buy_course_form input", function () {
            var $this = $(this),
                $fieldset = $this.parents("fieldset"),
                $buy_course_form_price = $(".buy_course_form .buy_course_form_price"),
                price_val = parseInt($(".buy_course_form_price_value", $buy_course_form_price).data("val"), 10);
            
            $fieldset.removeClass("has-danger");
            clearTimeout(check_timeout);
            if ($this.val()) {
                check_timeout = setTimeout(function () {
                    $.ajax({
                        type: 'POST',
                        url: "/order/check_promocode",
                        data: {
                            promoCode: $this.val(),
							tariffId: tariffId
							
                        },
                        dataType: undefined,
                        success: function(e) {
                            $buy_course_form_price.addClass("_discount");
                            $(".buy_course_form_price_value", $buy_course_form_price).html(SplitNumber(price_val - (price_val / 100 * e.discount)));
                        },
                        error: function(e) {
                            $fieldset.addClass("has-danger");
                            $buy_course_form_price.removeClass("_discount");
                            $(".buy_course_form_price_value", $buy_course_form_price).html(SplitNumber(price_val));
                        }
                    });
                }, 500);
            } else {
                $buy_course_form_price.removeClass("_discount");
                $(".buy_course_form_price_value", $buy_course_form_price).html(SplitNumber(price_val));
            }
        });
    
        function SplitNumber (num) {
            return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') + ' <span class="ruble">₽</span>';
        }
    }());
    
    // Buy promo tariff popup
    (function() {
        $(".buy-promo-tariff").on("click", function (e) {
            e.preventDefault();
    
            if (!window.authorizedSession) {
                jgntShowAuth(false, 'auth');
                return;
            }
    
            var $elModal = $('#jgntModalMiddle');
    
            $elModal.find(".modal-title").html("Покупка");
            $elModal.attr('class', 'jgnt_modal_middle modal fade');
            $elModal.modal('show');
            $elModal.find('.modal-body').addClass("no-padding").html('<div class="jgnt_inv jgnt_form jgnt_form_validation"><div class="inv_error">Ошибка отправки. Пожалуйста, попробуйте позже.</div><form action="#" class="jgnt_form buy_promo_tariff_form"><fieldset><div class="buy_promo_tariff_form_title">Ознакомительная подписка на&nbsp;просмотр 5&nbsp;видео в&nbsp;течение 14&nbsp;дней</div><p class="buy_promo_tariff_form_price">Бесплатно</p><button class="jgnt_btn" type="button">Попробовать</button></form></div>');
        });
    
        $body.on("click", ".buy_promo_tariff_form button.jgnt_btn", function (e) {
            e.preventDefault();

            $(".buy_promo_tariff_form").parents(".jgnt_inv").removeClass("inv-show-error");
            $.ajax({
                type: 'POST',
                url: "/order",
                data: {
                    tariffId: "1",
                    masterclassId: "0",
                    email: window.authorizedSession.email,
                    promoCode: ""
                },
                dataType: undefined,
                success: function(e) {
                    if (e.status === 2) {
                        localStorage['promo-tariff-success'] = true;
                        window.location.reload();
                    }
                },
                error: function(e) {
                    $(".buy_promo_tariff_form").parents(".jgnt_inv").addClass("inv-show-error");
                }
            });
        });
    
        if (window.authorizedSession) {
            if (typeof localStorage['promo-tariff-success'] !== 'undefined') {
                localStorage.removeItem("promo-tariff-success");
                var $elModal = $('#jgntModalMiddle');
                
                $elModal.find(".modal-title").html("Покупка");
                $elModal.attr('class', 'jgnt_modal_middle modal fade');
                $elModal.modal('show');
                $elModal.find('.modal-body').removeClass("no-padding").html('<form action="#" class="jgnt_form buy_promo_tariff_form"><fieldset><div class="buy_promo_tariff_form_title">ПРОМО-ТАРИФ АКТИВИРОВАН</div><br><a href="#" class="jgnt_btn btn" data-dismiss="modal">Закрыть окно</a></form>');
            }
        }
    }());
    
    // Buy tariff page
    (function() {
        var $form = $(".subscription_item_info form"),
            $check_button = $(".btn", $form),
            $promocode_input = $("input[name='sert_id']", $form),
            $promocode_percent = $(".promocode-percent", $form),
            $total = $(".total em", $form),
            $fieldset = $promocode_input.parents("fieldset"),
            $agree_check = $("input[name='add_guest_list']", $form),
            total_price = parseInt($total.data("value"), 10),
            promocode = "",
            ready_send = true;
    
        $check_button.on('click', function (e) {
            e.preventDefault();
            
            if ($promocode_input.val().length) {
                if (!window.authorizedSession) {
                    localStorage['buy-tariff-auth'] = JSON.stringify({
                        submit: false,
                        promocode: $promocode_input.val()
                    });
                    jgntShowAuth(false, 'auth');
                    return;
                }
				
				var tariff_id = parseInt($form.attr('data-tariff_id'));
                
                $.ajax({
                    type: 'POST',
                    url: "/order/check_promocode",
                    data: {
                        promoCode: $promocode_input.val(),
						tariffId: tariff_id
                    },
                    dataType: undefined,
                    success: function(e) {
                        promocode = $promocode_input.val();
                        $promocode_input.val("");
                        $promocode_percent.html("-" + e.discount + "%");
                        $total.html(SplitNumber(Math.round(total_price - (total_price / 100 * e.discount))));

                        if (e.discount === 100) {
                            $('.subscription_item_info button').text('Активировать');
                            $('.subscription_item_info p').hide();
                        } else {
                            $('.subscription_item_info button').text('Оплатить');
                            $('.subscription_item_info p').show();
                        }
                    },
                    error: function(e) {
                        promocode = "";
                        $promocode_input.val("");
                        $fieldset.addClass("has-danger");
                        $total.html(SplitNumber(total_price));
                        $promocode_percent.html("0%");
                    }
                });
            }
        });
    
        $promocode_input.on("change input", function () {
            $fieldset.removeClass("has-danger");
        }).on("keydown", function (e) {
            if (e.which === 13) {
                e.preventDefault();
                $check_button.click();
            }
        });
    
        $agree_check.on("change", function () {
            $(this).parents(".custom-checkbox").removeClass("has-danger");
        });
    
        $form.on("submit", async function (e) {
            e.preventDefault();
            
            if ($agree_check.get(0).checked) {
                if (!window.authorizedSession || !window.authorizedSession.email || window.authorizedSession.email == "") {
                    localStorage['buy-tariff-auth'] = JSON.stringify({
                        submit: true,
                        promocode: promocode
                    });

                    window.authorized = function(payload) {
                        window.location.hash = '';
                        window.location.reload();
                    }

                    jgntShowAuth(false, 'auth');
                    return;
                }
                
                if (ready_send) {
                    ready_send = false;

                    // send metrika
                    sendConversionEvent();

                    // send ecom data
                    if (typeof window.ecomPurchaseData !== "undefined") {
                        dataLayer.push(window.ecomPurchaseData);
                        console.warn("Sended to datalayer", JSON.stringify(window.ecomPurchaseData, null, 1));
                        await new Promise(r => setTimeout(r, 500));

                    }
                    
                    $.ajax({
                        type: 'POST',
                        url: "/order",
                        data: {
                            tariffId: $form.data("tariff_id"),
                            masterclassId: 0,
                            email: window.authorizedSession.email,
                            promoCode: promocode
                        },
                        dataType: "json",
                        success: function(e) {
                            $("#paymentForm").remove();
                            if (e.form !== undefined) {
                                $body.append(e.form);
                                $("#paymentForm").submit();
                            }

                            // Активация бесплатного тарифа
                            if (e.status === 2) {
                                var $elModal = $('#jgntModalMiddle');

                                $elModal.find(".modal-title").html("Подписка на Мастер-классы");
                                $elModal.attr('class', 'jgnt_modal_middle modal fade');
                                $elModal.modal('show');
                                $elModal.find('.modal-body')
                                    .removeClass("no-padding")
                                    .html('<form action="#" class="jgnt_form buy_promo_tariff_form"><fieldset><div class="buy_promo_tariff_form_title">ВАШ ЗАКАЗ АКТИВИРОВАН</div><br><a href="#" class="jgnt_btn btn" data-dismiss="modal">Закрыть окно</a></form>');

                                $('.jgnt_btn').click(function(){
                                    if (e.url !== undefined) {
                                        window.location.href = e.url;
                                    } else {
                                        window.location.href = '/profile';
                                    }
                                });
                            } else {
                                if (e.url !== undefined) {
                                    window.location.href = e.url;
                                }
                            }
                        },
                        error: function(e) {
                            ready_send = true;
                            
                            var $elModal = $('#jgntModalMiddle'),
                                message = 'Ошибка отправки. Пожалуйста, попробуйте позже.';

                            $elModal.find(".modal-title").html("Ошибка");
                            $elModal.attr('class', 'jgnt_modal_middle modal fade');

                            if (typeof e.responseJSON.message != 'undefined') {
                                message = e.responseJSON.message;
                            }

                            $elModal.find('.modal-body')
                                .removeClass("no-padding")
                                .html('<form action="#" class="jgnt_form buy_promo_tariff_form"><fieldset><div class="buy_promo_tariff_form_title">'+message+'</div><br><a href="#" class="jgnt_btn btn" data-dismiss="modal">Закрыть окно</a></form>');

                            $elModal.modal('show');
                        }
                    });
                }
            } else {
                $agree_check.parents(".custom-checkbox").addClass("has-danger");
            }
        });
    
        if (window.authorizedSession) {
            if (typeof localStorage['buy-tariff-auth'] !== 'undefined') {
                var loading_data = JSON.parse(localStorage['buy-tariff-auth']);
                
                localStorage.removeItem("buy-tariff-auth");
                $promocode_input.val(loading_data.promocode);
                $check_button.click();
                if (loading_data.submit) {
                    $agree_check.get(0).checked = true;
                    promocode = loading_data.promocode;
                    $form.submit();
                }
            }
        }
    
        function SplitNumber (num) {
            return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ') + ' <span class="sign">₽</span>';
        }
    }());
    
    // Invite friends popup
    (function () {
        $body.on("submit", ".js-invite_form", function (e) {
            e.preventDefault();
        
            var $this = $(this),
                $jgnt_inv = $this.parents(".jgnt_inv"),
                email_data = [];
        
            if( typeof jgntNod !== 'undefined' ){
                jgntNod.performCheck();
            
                if(jgntNod.areAll('valid') ){
                    if (!emailValidation($("input[name='person_email']", $this))) {
                        $(".inv_error", $jgnt_inv).html("Неправильный формат email");
                        $jgnt_inv.addClass('inv-show-error');
                        return;
                    }
                    
                    $jgnt_inv.removeClass('inv-show-error');
                    $("input[name='person_email']", $this).each(function(pos) {
                        email_data[pos] = {};
                        email_data[pos]["email"] = this.value;
                    });
                    $.ajax({
                        type: 'POST',
                        url: "/profile/api/referal",
                        data: {
                            friends: email_data
                        },
                        dataType: undefined,
                        success: function(e) {
                            if (e.status === 'success') {
                                $this.parents(".modal-content").find(".close").click();
                                setTimeout(function () {
                                    jgntShowSucc( false, "succ" );
                                }, 500);
                            }
                        },
                        error: function(e) {
                            $(".inv_error", $jgnt_inv).html("Ошибка отправки. Пожалуйста, попробуйте позже.");
                            $jgnt_inv.addClass('inv-show-error');
                        }
                    });
                }
            }
        });
        
        function emailValidation ($emails) {
            var valid = true;
            
            $emails.each(function() {
                if (!new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i).test($(this).val())) {
                    var $this = $(this);
                    setTimeout(function () {
                        $this.parents("fieldset").addClass("has-danger");
                    }, 1000);
                    valid = false;
                }
            });
            
            return valid;
        }
    }());
    
    $(".buy-subscription").on("click", function(e) {
        e.preventDefault();

        $(".video-tarifs._hidden").removeClass("_hidden");
        $(this).parents(".video-message").remove();
    });
    
    $(".js-promo-video").on("click", function (e) {
        e.preventDefault();
        jgntShowVideoPromo();
    });
    
    $window.on("orientationchange", function() {
        jgntJSUpdateResize();
    });
    
    $(".party_info_gallery_item--more").on("click", function (e) {
        var $this = $(this);
        
        if ($this.hasClass("party_info_gallery_item--more")) {
            e.preventDefault();
            $(".party_info_gallery_hidden").removeClass("party_info_gallery_hidden");
            $this.removeClass("party_info_gallery_item--more").addClass("custom_picbox").find("span").remove();
        }
    });
});

function jgntShowVideoPromo () {
    if( $("body").hasClass("modal-open") )
        return;
    
    var elModal = $('#jgntModalMiddle');
    elModal.find(".modal-title").html( "Промо" );
    elModal.attr( 'class', 'jgnt_modal_middle jgnt_modal_video modal fade' );
    elModal.modal('show');
    
    var strContainer = elModal.find( '.modal-body' );
    
    strContainer.html('<div class="promo-video"><script src="//fast.wistia.com/embed/medias/24pecj88eo.jsonp" async></script><script src="//fast.wistia.com/assets/external/E-v1.js" async></script><div class="wistia_responsive_padding"><div class="wistia_responsive_wrapper"><div class="wistia_embed wistia_async_24pecj88eo videoFoam=true">&nbsp;</div></div></div></div>');
}

$(".comments_form").submit(function () {
    var $form = $(this);

    $.ajax({
        url: $form.prop('action'),
        type: 'POST',
        dataType: 'json',
        data: $form.serialize(),
        timeout: 10000,
        
        success: function(data) {

            $('.comments_list').prepend(data.html);
            
        },

        statusCode: {
            401: function(xhr) {
              jgntShowAuth(false, 'auth');
            }
        }/*,
        
        error: function(data) {
            self.elements.$message.text("Ошибка сервера, попробуйте восстановить пароль ещё раз");
        }*/
    });
    
    //$.post($form.prop('action'), $form.serialize(), 'json').always(function () {
    //    $form[0].reset();
    //}).done(function (data) {
    //    $('.comments_list').prepend(data.html);
    //});
    
    return false;
});

/********************
@ez sep 2017 
profile video page
********************/
$(document).ready(function() {
	
	// initi custom scroll
	$('.scrollblock').each(function() {
		SimpleScrollbar.initEl($(this).get(0));
    })
	
    $('._slider').each(function () {
        var $el = $(this);
		
		var $outer = $el.parents('.slider-outer');

		var space = $el.outerWidth() * 1.6 / 100;

		var options = { slides: 4, space: space };

        var swiper = new Swiper($el, {
          
            grabCursor: true,
            slidesPerView: options.slides,
            spaceBetween: options.space,
		
			iOSEdgeSwipeDetection: true,
			preloadImages: false,
			lazyLoading:true,
			
			prevButton: $outer.find('.slider-nav[action="right"]'),
			nextButton: $outer.find('.slider-nav[action="left"]'),
			
            breakpoints: {
                960: {
                    slidesPerView: 2,
                    spaceBetweenSlides: $el.outerWidth() * 2 / 100
                },
				599: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 0
				}
            }
        });

    });

    $('body').on('click', '.remove-fav', function(e) {
		
		e.stopPropagation();
		
		var $elem = $(this).parents('.personal-video-item'),
            $catalog_more = $('.catalog_more'),
		    masterclass_id = parseInt($(this).attr('data-masterclass-id')),
		    type_id = parseInt($(this).attr('data-type')),
            recipe_id = '',
            data = '';
		
		if (type_id == 1) {
			recipe_id = parseInt($(this).attr('data-recipe-id'));
			data = 'masterclass_id='+masterclass_id+'&recipe_id='+recipe_id+'&type='+type_id;
		} else if (type_id == 2) {
			data = 'masterclass_id='+masterclass_id+'&type='+type_id;
		}

		data += '&count=' + $elem.parent().find('.personal-video-item').length;
		
		$.post('/masterclasses/favorites', data, function(response) {
		    if (response.status == 'success') {
                if ($catalog_more.length) {
                    if (response.html != null) $catalog_more.before(response.html);
                    if (response.next_page != null) $catalog_more.attr('data-ajax-url', response.next_page);
                    if (response.final === 'true') $catalog_more.hide();
                } else {
                    if (response.html != null) $elem.parent().append(response.html);
                }
                $elem.remove();
			}
		})
		return false;
	})
	
	if (location.href.indexOf('/masterclasses/') != -1) {
		if (window.location.hash) {
			setTimeout(function() {
				var id = window.location.hash.split('#')[1];
				$('.playlist_item[data-recipe-id="'+id+'"]').find('.playlist_item_img').trigger('click');
			})
		}
	}
	
	$(window).on('load resize', function() {
		
		if (window.innerWidth > 960) {
			 $('.scrollblock').css('height', '24em');
		} else if (window.innerWidth < 600) {
			var height = 0;
			$('.course-video-list-item').each(function() {
				height += $(this).outerHeight();
			})
			
			if (height > 400) {
				$('.scrollblock').css('height', '400px')
				
			} else {
				$('.scrollblock').css('height', 'auto')
				$('.ss-content').css('padding-right','40px');
			}
		} else {
			$('.scrollblock').css('height', 'auto');
			
		}
	})
	
	$('.course-list-placeholder').on('click', function() {
		$(this).toggleClass('_open');
		$('.course-list-content').toggleClass('_open');
	})
	
	$('.video-list-title').on('click', function() {
		$(this).toggleClass('_open');
	})
	
	$('.course-list-item, .course-tab-item').on('click', function() {
		
		if ($(this).hasClass('_active')) return false;
		
		$('.course-list-item, .course-tab-item').removeClass('_active');
		$(this).addClass('_active');

		var id = $(this).attr('data-masterclass-id');
		
		$('.course-info').removeClass('_active');
		$('.course-info[data-masterclass-id="'+id+'"]').addClass('_active');
		
		$('.mobile-course-placeholder').text($(this).text());
	})
	
	/*********/

    $('body').on('click', '.favorite-trigger', function() {

		var $trigger = $(this);
		
		var masterclass_id = parseInt($(this).attr('data-masterclass-id'));
		var type_id = parseInt($(this).attr('data-type'));
		
		if (type_id == 1) {
			var recipe_id = parseInt($(this).attr('data-recipe-id'));
			var data = 'masterclass_id='+masterclass_id+'&recipe_id='+recipe_id+'&type='+type_id;
		} else if (type_id == 2) {
			var data = 'masterclass_id='+masterclass_id+'&type='+type_id;
		}
		
		$.post('/masterclasses/favorites', data, function(response) {
			if (response.status == 'success') {
				if ($trigger.hasClass('_active')) {
					$trigger.removeClass('_active');
				} else {
					$trigger.addClass('_active');
				}
			}
		})

	})
})

